import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import InterAdvantagesJSON from '../../assets/data/InterAdvantages.json'

import useWidth from 'src/hooks/window/useWidth'

type InterAdvantagesProps = {
  icon: string;
  text: string;
}

const WIDTH_MD = 768

const Vantagens = () => {
  const windowWidth = useWidth(300)

  const data = usePageQuery()
  return (
    <section className='pt-4 pb-5'>
      <div className='container'>
        <div className='row align-items-center'>
          {windowWidth > WIDTH_MD &&
            <div className='col-12 col-md-5 mt-5 mt-md-0'>
              <Img fluid={data.VantagensFundosInter.fluid} alt='Casal acessando notebook' />
            </div>
          }
          <div className='col-12 col-lg-6 offset-lg-1 px-lg-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 mb-md-4'>
              Vantagens em investir nos fundos com o Inter
            </h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 text-grayscale--500 mb-4'>
              Aqui, você diversifica a sua carteira e potencializa seus ganhos.
            </p>
            {InterAdvantagesJSON.map((item: InterAdvantagesProps, index: number) => (
              <div key={index} className='d-flex align-items-lg-center mt-2'>
                <div className='mr-3 pt-0 pb-lg-3'><OrangeIcon size='MD' color='#FF7A00' icon={item.icon} /></div>
                <div>
                  <p
                    className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--500'
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Vantagens
