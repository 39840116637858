import styled from 'styled-components'

export const DisclaimerDiv = styled.div`
  .icon {
    min-width: 35px
  }

  img {
    object-fit: contain;
  }
`
