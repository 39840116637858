import React from 'react'
import Img from 'gatsby-image'
import Link from 'components/GatsbyLinkWrapper'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useWidth from 'src/hooks/window/useWidth'

import usePageQuery from '../../pageQuery'

import { ContentWrapper } from './style'
const WIDTH_MD = 768

const Portabilidade = () => {
  const data = usePageQuery()
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentWrapper id='portabilidade' className='py-5 bg-orange--extra d-flex position-relative align-items-center justify-content-center'>
      <div className='container'>
        <div className='row align-items-center justify-content-center justify-content-lg-between'>
          <div className='col-12 col-md-6 col-lg-5 pr-lg-0 mb-4 mb-md-0'>
            {windowWidth <= WIDTH_MD
              ? <Img fluid={data.fundosPortabilidadeMobile.fluid} alt='Imagem de gráfico que representa fundos de investimentos' />
              : <Img fluid={data.fundosPortabilidadeDesktop.fluid} alt='Imagem de gráfico que representa fundos de investimentos' />
            }
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='text-white fs-32 lh-40 fs-lg-40 lh-lg-50 fw-600 mb-3'>
              <span className='d-lg-block'>Seus fundos rendem</span> mais no Inter
            </h2>
            <p className='text-white fs-16 lh-19 fs-lg-18 lh-lg-22'>
              Clientes Black e PJ Pro têm direito a 20% e clientes Win, PJ Enterprise, Corporate, Upper Middle e Middle Market a
              40% do rebate em cashback em Pontos Loop direto na conta. Para receber o benefício, é necessário que a soma dos
              potenciais cashback em Pontos Loop de todos os fundos investidos totalize ao menos R$ 10,00*.
            </p>
            <p className='text-white fs-14 lh-16 fs-lg-16 lh-lg-18'>*Atualizado em 08/2024</p>
            <Link
              to='/pra-voce/investimentos/fundos-de-investimento/portabilidade/'
              className='btn btn--lg text-orange--extra bg-white rounded-2 mt-4 fw-600 text-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: ' Fazer portabilidade de fundos',
                  section_name: 'Seus fundos rendem ais no Inter',
                })
              }}
            >
              Fazer portabilidade de fundos
            </Link>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default Portabilidade
