import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import HeroBannerTablet from './../../assets/images/conte-com-nossos-especialistas-tablet.png'
import HeroBannerLG from './../../assets/images/conte-com-nossos-especialistas-desktop-lg.png'
import HeroBannerXL from './../../assets/images/conte-com-nossos-especialistas-desktop-xl.png'

export const OurExpertsSection = styled.section`
  @media ${device.tablet} {
    min-height: 380px;
    background: url(${HeroBannerTablet}) no-repeat;
    background-size: contain;
    background-position: left center;
  }
  @media ${device.desktopLG} {
    min-height: 457px;
    background: url(${HeroBannerLG}) no-repeat;
    background-size: contain;
  }
  @media ${device.desktopXL} {
    min-height: 561px;
    background: url(${HeroBannerXL} no-repeat);
    background-size: contain;
  }
`
