import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import StayInJSON from '../../assets/data/StayIn.json'

type FiquePorDentroProps = {
  icon: string;
  title: string;
  description: string;
  cta: string;
  url: string;
}

const FiquePorDentro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-5 px-lg-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 text-md-center text-lg-left'>
              Fique por dentro do mercado econômico
            </h2>
            <p className='fs-16 fs-lg-18 lh-19 lh-lg-22 text-grayscale--500 text-md-center text-lg-left mt-md-4 mb-0'>
              Acompanhe as últimas notícias do setor e análises dos nossos especialistas para ter mais segurança na hora de
              <a
                href='https://inter.co/pra-voce/investimentos/'
                target='_blank' rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_06',
                    element_action: 'click button',
                    element_name: 'fazer investimentos',
                    section_name: 'Fique por dentro do mercado econômico',
                    redirect_url: 'https://inter.co/pra-voce/investimentos/',
                  })
                }}
              >
                <span className='text-orange-extra fw-600'>
                  {' '}fazer investimentos
                </span>
              </a>.
            </p>
          </div>
          <div className='col-12 col-lg-6 offset-lg-1 mt-5 mt-md-0 order-md-last mt-md-3'>
            {StayInJSON.map((item: FiquePorDentroProps, index: number) => (
              <div key={index} className='bg-white border rounded-5 px-4 py-3 mb-3'>
                <div className='d-flex'>
                  <div className='mr-2'><OrangeIcon size='MD' color='#FF7A00' icon={item.icon} /></div>
                  <div><span className='fs-16 lh-19 text-orange--extra fw-600'>{item.title}</span></div>
                </div>
                <div className='d-md-flex justify-content-md-between'>
                  <div><p
                    className='fs-14 lh-16 fw-600 text-grayscale--500 mt-4'
                    dangerouslySetInnerHTML={{ __html: item.description }}
                       />
                  </div>
                  <div>
                    <p className='fs-12 lh-15 text-orange--extra fw-600 text-right mt-md-4'>
                      <a
                        href={item.url}
                        title={item.title}
                        className='mr-2'
                        target='_blank'
                        rel='noreferrer'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_06',
                            element_action: 'click button',
                            element_name: item.title,
                            section_name: 'Fique por dentro do mercado econômico',
                            redirect_url: item.url,
                          })
                        }}
                      >{item.cta}
                      </a>
                      <OrangeIcon size='MD' color='#FF7A00' icon='arrow-right' />
                    </p>
                  </div>
                </div>
              </div>
           ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FiquePorDentro
