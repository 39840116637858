import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ContentWrapper = styled.div`
  min-height: 701px;
  
  @media ${device.tablet} {
    min-height: 417px;
  }
  @media ${device.desktopLG} {
    min-height: 537px;
  }
`
