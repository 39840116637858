/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { InjectedTranslateProps, translate } from 'react-i18next'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDataLayerHeader from 'src/hooks/useDataLayer/dataLayerHeader'

import { Checkbox } from './style'

type AcceptTermsProps = {
  accept: string | boolean;
  setAccept: (value: boolean) => boolean | void;
  name: string;
  t?: (value: string) => void;
  dataLayer?: IDataLayerParams;
  typeDataLayer?: string;
  invertColors?: boolean;
}
function AcceptTermsEnglish ({ accept, setAccept, name, t, dataLayer, typeDataLayer, invertColors }: AcceptTermsProps & InjectedTranslateProps) {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ sendDatalayerEventHeader ] = useDataLayerHeader()

  function handleCheck (event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAccept(inputValue)
  }

  return (
    <Checkbox $invertColors={invertColors}>
      <input
        name={name}
        value={accept.toString()}
        id={name}
        type='checkbox'
        className='form-input-check'
        onChange={handleCheck}
      />
      <label className='form-label-check fs-14 lh-17' htmlFor={name}>
        {t('authorization')}
        <a
          className='fw-700' title={t('titlePrivacyPolicy')}
          href={t('privacyLink')}
          target='_blank' rel='noreferrer'
          onClick={() => {
            typeDataLayer === 'ga_event_header'
              ? sendDatalayerEventHeader({
                  section: 'null',
                  sub_section: 'null',
                  element_action: 'click button',
                  element_name: t('privacyPolicy'),
                  c_page: window.location.href,
                  redirect_url: t('privacyLink'),
              })
              : dataLayer && sendDatalayerEvent({
                ...dataLayer,
                section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
                section_name: dataLayer ? dataLayer.section_name : '',
                element_action: 'click button',
                element_name: t('privacyPolicy'),
                redirect_url: t('privacyLink'),
            })
          }}
        > {t('privacyPolicy')}
        </a>
      </label>
    </Checkbox>
  )
}

AcceptTermsEnglish.defaultProps = {
  name: 'aceite',
}

export default translate('OpenAccountRightForm')(AcceptTermsEnglish)
