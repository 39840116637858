import React from 'react'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import ScrollTo from 'src/components/ScrollTo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { WIDTH_MD } from 'src/styles/breakpoints'

import usePageQuery from '../../pageQuery'

import { OurExpertsSection } from './style'

const NossosEspecialistas = () => {
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()

  return (
    <OurExpertsSection className='d-flex position-relative align-items-center pb-5 pb-md-0'>
      <div className='container'>
        <div className='row'>
          {windowWidth < WIDTH_MD &&
            <div className='col-12 col-md-5 d-flex justify-content-center'>
              <Img fluid={data.ConteComNossosEspecialistasMobile.fluid} alt='Homem acessando notebook' />
            </div>}
          <div className='col-12 col-md-6 offset-md-6 px-lg-0'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 mt-4 mt-md-0'>
              Conte com nossos especialistas para potencializar seu patrimônio
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500 mt-3 mb-4'>
              <span className='d-xl-block'>Confira a seleção dos
                <a
                  href='https://interinvest.inter.co/fundos'
                  title='Confira a seleção dos Top Funds'
                  className='fw-600 text-orange--extra'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Top Funds',
                      section_name: 'Conte com nossos especialistas para potencializar seu patrimônio',
                      redirect_url: 'https://interinvest.inter.co/fundos',
                    })
                  }}
                > Top Funds
                </a> feita pelos
              </span>
              <span className='d-xl-block'>nossos especialistas e se inscreva na nossa</span> newsletter com conteúdos exclusivos e educativos.
            </p>
            <ScrollTo
              to='#fundos'
              section='dobra_05'
              sectionName='Conte com nossos especialistas para potencializar seu patrimônio'
              elementName='Conheça os fundos disponíveis'
              styles='btn btn--lg text-white bg-orange rounded-2 mt-2 text-none'
            >
              Conheça os fundos disponíveis
            </ScrollTo>
          </div>
        </div>
      </div>
    </OurExpertsSection>
  )
}

export default NossosEspecialistas
