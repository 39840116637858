import React from 'react'
import Img from 'gatsby-image'
import ScrollTo from 'src/components/ScrollTo'
import { Link } from 'gatsby'
import useWidth from 'src/hooks/window/useWidth'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import headerJson from '../../assets/data/header.json'
import Home from '@interco/icons/build-v4/orangeds/MD/home'

import usePageQuery from '../../pageQuery'

import { HeroSection } from './style'

const WIDTH_MD = 768

const Hero = () => {
  const data = usePageQuery()
  const windowWidth = useWidth(300)

  return (
    <HeroSection
      id='fundos-com-cashback'
      className='pt-3 pb-5 py-md-0 bg-gray d-flex position-relative align-items-center justify-content-between'
    >
      <div className='container mb-xl-4'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mt-4 mt-md-0 order-md-last '>
            <Img fluid={data.FundosComCashback.fluid} alt='Mulher sorrindo' className='float-right' />
          </div>
          <div className='col-12 col-md-6 pr-lg-0'>
            {windowWidth >= WIDTH_MD &&
              <div className='bread'>
                <Link
                  to='/'
                  className='d-none d-md-inline'
                ><Home height={24} width={24} color='#6A6E81' />
                </Link>
                <OrangeIcon className='d-none d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                <Link
                  to='/pra-voce/investimentos/'
                  className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                >
                  {headerJson.breadcrumb[0]}
                </Link>
                <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
              </div>
            }
            <h1 className='fs-32 lh-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 mt-3 mt-md-0 text-grayscale--500 fw-600'>
              <span className='d-xl-block'>
                Fundos para você
              </span>
              <span className='d-xl-block'>
                investir a partir de
              </span>R$ 100,00
            </h1>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 mt-3 text-grayscale--500'>
              Quer aumentar o desempenho dos seus investimentos? Invista nos fundos das melhores gestoras do mercado e
              com a possibilidade de cashback em Pontos Loop para clientes Black, Win e clientes PJ segmentados.
            </p>
            <ScrollTo
              to='#fundos'
              section='dobra_01'
              sectionName='Fundos de investimento com cashback a partir de R$ 100,00'
              elementName='Conheça os fundos disponíveis'
              styles='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none mt-3'
            >
              Conheça os fundos disponíveis
            </ScrollTo>
          </div>
        </div>
      </div>
    </HeroSection>
  )
}

export default Hero
