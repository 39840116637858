
import React from 'react'
import FAQ from 'src/components/Faq'

type Faq = {
  faq: {question: string; answer: string}[];
}

const FAQComponent = (props: Faq) => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h2 className='fs-24 lh-30 fw-600 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'>
              Perguntas frequentes
            </h2>
          </div>
          <FAQ
            answerData={props.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='summary-content'
          />
        </div>
      </div>
    </section>
  )
}

export default FAQComponent
