import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from '../../../../../../components/UI/MarkdownIcon/OrangeDsIcon'

import { DisclaimerDiv } from './style'

import InvestmentsLogos from 'src/components/InvestmentsLogos/_index'

const Attention = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const link1 = 'https://marketing.bancointer.com.br/arquivos/pdf/idtvm/Invest-Documento-Procedimentos-1.pdf?_gl=1*1ujenlv*_gcl_au*MTU1MTg4ODAxNi4xNjg3MTc4NDEy&_ga=2.229489818.761707871.1688478086-1703710166.1684156661'

  const link2 = 'https://marketing.bancointer.com.br/arquivos/pdf/idtvm/211011-Invest-Documento-SLA-1.pdf?_gl=1*1ujenlv*_gcl_au*MTU1MTg4ODAxNi4xNjg3MTc4NDEy&_ga=2.229489818.761707871.1688478086-1703710166.1684156661'

  const link3 = 'https://marketing.bancointer.com.br/arquivos/pdf/idtvm/informacoes-sobre-remuneracao-do-distribuidor-idtvm.pdf?_gl=1*1flq44w*_gcl_au*MTU1MTg4ODAxNi4xNjg3MTc4NDEy&_ga=2.4037814.761707871.1688478086-1703710166.1684156661'

  const link4 = 'https://inter.co/inter-dtvm/'

  return (
    <DisclaimerDiv className='bg-grayscale--100 py-5'>
      <div className='container py-lg-4'>
        <div className='row'>
          <div className='col-12 align-items-center d-flex'>
            <div className='icon'>
              <OrangeIcon size='MD' color='#FF7A00' icon='attention' />
            </div>
            <div>
              <p className='fs-12 lh-14 fw-400 text-grayscale--400 mb-3'>
                Toda comunicação através da rede mundial de computadores está sujeita a interrupções ou atrasos, podendo impedir ou prejudicar o envio de ordens ou a recepção de informações atualizadas. A Inter DTVM, exime-se de responsabilidade por danos sofridos por seus clientes decorrentes de falha de serviços disponibilizados por terceiros, incluindo, mas não se limitando, à aqueles conexos à rede mundial de computadores. Em caso de indisponibilidade da ferramenta de negociação on-line, as negociações deverão ser realizadas por telefone diretamente com a Mesa de Operações por meio dos nossos canais de atendimento.
              </p>

              <p className='fs-12 lh-14 fw-400 text-grayscale--400 mb-3'>Consulte informações sobre os procedimentos adotados em caso de instabilidade ou indisponibilidade de plataformas de negociação
                <a
                  href={link1}
                  target='_blank' rel='noreferrer'
                  className='fw-700 text-orange--extra'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      section_name: 'Disclaimer',
                      element_action: 'click button',
                      element_name: 'neste documento',
                      redirect_url: link1,
                    })
                  }}
                >
                  {' '}neste documento
                </a>.
              </p>

              <p className='fs-12 lh-14 fw-400 text-grayscale--400 mb-3'> Consulte os indicadores de níveis de serviço de atendimento e latência das plataformas
                <a
                  href={link2}
                  target='_blank' rel='noreferrer'
                  className='fw-700 text-orange--extra'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      section_name: 'Disclaimer',
                      element_action: 'click button',
                      element_name: 'neste documento',
                      redirect_url: link2,
                    })
                  }}
                >
                  {' '}neste documento
                </a>.
              </p>

              <p className='fs-12 lh-14 fw-400 text-grayscale--400 mb-31'>Fundos de investimento não contam com garantia do administrador, do gestor, de qualquer mecanismo de seguro ou Fundo Garantidor de Crédito – FGC. Os fundos utilizam estratégias que podem resultar em significativas perdas patrimoniais para seus cotistas. Os fundos podem estar expostos à significativa concentração em ativos financeiros de poucos emissores, com os riscos daí decorrentes. Para avaliação da performance de um fundo de investimento, é recomendável a análise de, no mínimo 12 (doze) meses. Leia o formulário de informações complementares, lâmina de informações essenciais e o regulamento antes de investir. Rentabilidade obtida no passado não representa garantia de resultados futuros. A rentabilidade divulgada não é liquida de impostos. Os indicadores econômicos são meras referências econômicas, e não metas ou parâmetros de performance. Os produtos apresentados podem não ser adequados para todos os investidores. Antes de investir verifique as características do fundo e sempre invista de acordo com o seu perfil de investimentos. A instituição é remunerada pela distribuição do produto. Para maiores detalhes, consulte as informações sobre a
                <a
                  href={link3}
                  target='_blank'
                  rel='noreferrer'
                  className='fw-700 text-orange--extra'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      section_name: 'Disclaimer',
                      element_action: 'click button',
                      element_name: 'remuneração do distribuidor',
                      redirect_url: link3,
                    })
                  }}
                >
                  {' '}remuneração do distribuidor
                </a>.
              </p>

              <p className='fs-12 lh-14 fw-400 text-grayscale--400'>Para maiores informações sobre os serviços prestados pela Inter DTVM, acesse o
                <a
                  href={link4}
                  target='_blank' rel='noreferrer'
                  className='fw-700 text-orange--extra'
                  onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  section_name: 'Disclaimer',
                  element_action: 'click button',
                  element_name: 'link',
                  redirect_url: link4,
                })
                  }}
                >
                  {' '}link
                </a>.
              </p>

            </div>
          </div>
        </div>
        <InvestmentsLogos classes='d-flex flex-wrap justify-content-center mt-3' />
      </div>
    </DisclaimerDiv>
  )
}

export default Attention
