import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import { Wrapper } from './style'

import Hero from './sections/hero/_index'
import Portabilidade from './sections/portabilidade/_index'
import FundosDisponiveis from 'src/components/InvestmentsFilter/fundos-disponiveis/_index'
import Vantagens from './sections/vantagens/_index'
import NossosEspecialistas from './sections/nossos-especialistas/_index'
import FiquePorDentro from './sections/fique-por-dentro/_index'
import FAQ from './sections/faq/_index'
import Atencao from './sections/atencao/_index'

const FundosDeInvestimentos = () => {
  const type = 'PF'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <Portabilidade />
        <FundosDisponiveis type={type} searchBg='bg-grayscale--100' />
        <Vantagens />
        <NossosEspecialistas />
        <FiquePorDentro />
        <FAQ faq={pageContext.structuredData.faq} />
        <Atencao />
      </Layout>
    </Wrapper>
  )
}

export default FundosDeInvestimentos
